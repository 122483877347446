@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @import './css/reset.css';
  
  @import './assets/fonts/Open_Sans/Open_Sans.css';
}

@layer components {
  @import './css/mobile-landscape.css';
}
